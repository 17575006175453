// If you want to override variables do it here
@import "variables";

// Import styles
@import "@coreui/coreui/scss/coreui";

// Vendors
@import "vendors/simplebar";

// Custom styles for this theme
@import "theme";

// If you want to add custom CSS you can put it here
@import "custom";

:root {
  --cui-primary: #d32d30 !important;
  --cui-pagination-active-bg: #d32d30 !important;
  --cui-link-color: #d32d30 !important;
  --cui-link-hover-color: #8d2123 !important;
  --cui-link-color-rgb: #8d2123 !important;
}

a{
  text-decoration: none;
}

/* DEFAULT STYLE */

.btn-primary {
  background: #d32d30 !important;
  border: none !important;
}

.white-space-nowrap {
  white-space: nowrap;
}

.min-width-220 {
  min-width: 220px;
}

.dropdown-action {
  outline: none !important;
  border: none !important;
}
.dropdown-action::after {
  display: none !important;
}

.sidebar.sidebar-fixed {
  background: #1e222b;
}

.table-circle-wrapper {
  display: flex;
  align-items: center;
}

.table-circle-image {
  position: relative;
}

.table-circle-image img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: block;
}

.table-circle-image span {
  width: 8px;
  height: 8px;
  display: block;
  position: absolute;
  bottom: 2px;
  right: 0px;
  border-radius: 50%;
}

.table-circle-text {
  padding-left: 10px;
}

.table-circle-text h3 {
  margin: 0 0 1px 0;
  font-size: 16px;
  font-weight: 600;
}

.table-circle-text span {
  display: block;
  font-size: 13px;
  color: #878787;
}

.table{

}

.table thead tr th {
  background: #ebebeb !important;
  color: #000 !important;
  font-size: 14px;
  font-weight: 500;
  padding: 15px 10px;
}

.table tbody tr td {
  background: #fff !important;
  --cui-table-bg-type: #fff !important;
  color: #636363 !important;
  padding: 10px 10px;
  font-size: 14px;
}

.interval-selector button{
  margin-left: 10px;
}

.sidebar-nav .nav-group-toggle::after{
  background-color: #fff !important;
}

.chart-container > div{
  width: 100% !important;
  height: 300px !important;
}

.card-amount h3 {
  font-size: 18px;
  margin: 0 0 0;
}

.table-header input {
  height: 50px;
  max-width: 300px;
}

.react-datepicker__input-container input.range-picker {
  border: 1px solid #ddd;
  min-width: 250px;
  text-align: center;
  height: 40px;
  border-radius: 6px;
  outline: none;
  box-shadow: none;
}

.view-interval-buttons button {
  background: #fff;
  color: #000;
  font-size: 14px;
  margin-left: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px 12px;
  font-weight: 400;
}
.calendar-form-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.app-config-collage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 12px;
}

.app-config-collage h2 {
  margin: 0 0 0;
  font-size: 24px;
  color: #444;
}

.configure-app-screen h4 {
  font-size: 18px;
  color: #555;
}

.configure-app-screen h6 {
  font-size: 14px;
  color: #555;
  margin: 0 0 10px;
}

.table tbody tr:last-child td{
  border-bottom: 0px;
}

.table-heading {
  color: #d32d30;
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 0 0;
  padding-bottom: 0;
}

.table-top-heading{
  padding-bottom: 20px;
}
.table-top-heading .text-primary{
  font-size: 14px;
  color: #000 !important;
  font-weight: 600;
}
.badge {
  padding: 6px 10px 6px;
  font-size: 10px;
  font-weight: 500;
}

.chart-card-wrapper{
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
  background: #fff;
  padding: 30px;
  margin-bottom: 20px;
  border-radius: 6px;
}

.tag{
  padding: 6px 10px 7px !important;
  font-size: 10px !important;
  font-weight: 500 !important;
}

.login-auth-bg {
  background: url(../img/auth-bg.png) no-repeat center center / cover !important;
}

.btn-group .btn img {
  width: 4px;
}
.btn-group ul.dropdown-menu li a {
  font-size: 13px;
  font-weight: 500;
  padding: 5px 15px;
}
.menu-sidebar ul {
  padding: 10px 0;
  margin: 0;
  list-style: none;
}

.menu-sidebar ul .menu-side-item {
  display: block;
}
.sidebar-header {
  border-bottom: 1px solid #454e62 !important;
}
.menu-sidebar ul .menu-side-item a {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 14px 10px;
  border-bottom: 1px solid #454e62;
}

.menu-sidebar ul .menu-side-item .menu-side-dropdown {
  padding-left: 0px;
  background: #3b3d41;
}

.menu-sidebar ul .menu-side-item .menu-side-dropdown li a {
  padding: 10px 22px;
  font-size: 13px;
}

.sidebar .sidebar-footer {
  border-top: 1px solid #515151 !important;
}

.sidebar .sidebar-footer .logout-btn {
  background: #fff;
  color: #000;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  padding: 9px 16px 10px;
  width: 100%;
}

.menu-sidebar ul .menu-side-item .menu-side-dropdown li a:last-child {
  border-bottom: none !important;
}

.equall-button-input{
  padding-bottom: 10px !important;
}
.equall-button-input input{
  height: auto !important;
  margin-bottom: 0 !important;
}

.btn.btn-danger,
.btn.btn-primary {
  color: #fff !important;
  font-size: 14px;
}

.center-align th,
.center-align td {
  vertical-align: middle;
}

.table-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.partner-profile {
  display: flex;
  align-items: center;
  padding-bottom: 25px;
}

.partner-profile .in-left img {
  width: 107px;
  height: 107px;
  border-radius: 8px;
  display: block;
}

.partner-profile .in-left img.placeholder-image {
  background: #ddd;
  padding: 13px;
}

.partner-profile .in-right {
  padding-left: 30px;
}

.partner-profile .in-right h4 {
  margin: 0 0 15px 0;
  font-size: 18px;
}

.partner-profile .in-right label {
  margin: 0 0 0;
}

.store-partner-detail {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  margin-bottom: 30px;
}

.partner-documents {
  padding-top: 30px;
}

.partner-documents h3 {
  margin: 0 0 0;
  font-size: 22px;
}

.document-list {
  padding-top: 25px;
}

.red-subHeading {
  margin: 0 0 15px 0;
  font-size: 16px;
  color: #d32d30;
}

.banner-image {
  max-width: 30%;
  height: auto;
  display: block;
  margin-top: 10px;
}

.document-list h5 {
  margin: 0 0 15px 0;
  font-size: 16px;
  color: #d32d30;
}

.document-list ul {
  padding: 0px 0 0px 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: flex-start;
}

.document-list ul li {
  text-align: center;
  margin-right: 25px;
  background: #eee;
  padding: 15px;
  border-radius: 8px;
}

.document-list ul li.add-pdf {
  background: #ffe0e0;
}

.document-list ul img {
  width: 42px;
  height: 42px;
  display: inline-block;
}

.document-list ul span {
  display: block;
  font-size: 14px;
  font-weight: 600;
  padding-top: 5px;
}

.config-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.config-update-button {
  margin-left: auto;
}

/* GlobalLoader.css */
.global-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensures loader is on top */
}

.loader-content {
  z-index: 10000; /* Ensures loader content is on top */
  font-size: 1.5rem;
  font-weight: bold;
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
}

.tag {
  background-color: #007bff; /* Bootstrap primary color or any color you prefer */
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  margin: 0.25rem;
  font-size: 0.75rem;
  cursor: pointer;
  position: relative;
}

.tag-tooltip {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 0.25rem;
  padding: 0.5rem;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the tag */
  left: 50%;
  margin-left: -60px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;
}

.tag:hover .tag-tooltip {
  visibility: visible;
  opacity: 1;
}

.cards-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.card {
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.card-icon {
  font-size: 24px;
}

.card-content h5 {
  margin: 0;
  font-size: 16px;
  color: #555;
}

.card-amount h3 {
  margin: 0;
  font-size: 24px;
}

.transaction-table {
  width: 100%;
  border-collapse: collapse;
}

.transaction-table th,
.transaction-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.transaction-table th {
  background-color: #f5f5f5;
}

.transaction-table tr:hover {
  background-color: #f1f1f1;
}

.table td.amount-success {
  color: green !important;
}

.table td.amount-debit {
  color: red !important;
}

.input-wrapper {
  position: relative;
}

.has-right-label {
  padding-right: 40px; /* Add extra padding to make space for the label */
}

.right-label {
  position: absolute;
  right: 10px; /* Adjust this as necessary to fine-tune position */
  top: 50%;
  transform: translateY(-50%);
  color: gray;
  pointer-events: none; /* Make sure the label doesn't interfere with input clicks */
}

.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

// .header.header-sticky{
//     background: #fff !important;
// }
// .breadcrumb-item {
//     color: #000 !important;
// }
// .header-toggler {
//     color: #000 !important;
// }
// body {
//     background: #fff !important;
// }
// .footer {
//     background: #f3f4f7 !important;
// }
// .footer span {
//     color: #000 !important;
// }

[data-coreui-theme="dark"] {
  color-scheme: dark;
  --cui-blue: #0d6efd;
  --cui-indigo: #6610f2;
  --cui-purple: #6f42c1;
  --cui-pink: #d63384;
  --cui-red: #dc3545;
  --cui-orange: #fd7e14;
  --cui-yellow: #ffc107;
  --cui-green: #198754;
  --cui-teal: #20c997;
  --cui-cyan: #0dcaf0;
  --cui-black: #080a0c;
  --cui-white: #fff;
  --cui-gray: #6d7d9c;
  --cui-gray-dark: #323a49;
  --cui-gray-100: #f3f4f7;
  --cui-gray-200: #e7eaee;
  --cui-gray-300: #dbdfe6;
  --cui-gray-400: #cfd4de;
  --cui-gray-500: #aab3c5;
  --cui-gray-600: #6d7d9c;
  --cui-gray-700: #4a566d;
  --cui-gray-800: #323a49;
  --cui-gray-900: #212631;
  --cui-primary: #5856d6;
  --cui-secondary: #6b7785;
  --cui-success: #1b9e3e;
  --cui-info: #39f;
  --cui-warning: #f9b115;
  --cui-danger: #e55353;
  --cui-light: #f3f4f7;
  --cui-dark: #212631;
  --cui-primary-rgb: 88, 86, 214;
  --cui-secondary-rgb: 107, 119, 133;
  --cui-success-rgb: 27, 158, 62;
  --cui-info-rgb: 51, 153, 255;
  --cui-warning-rgb: 249, 177, 21;
  --cui-danger-rgb: 229, 83, 83;
  --cui-light-rgb: 243, 244, 247;
  --cui-dark-rgb: 33, 38, 49;
  --cui-primary-text-emphasis: #3634a3;
  --cui-secondary-text-emphasis: #212233;
  --cui-success-text-emphasis: #0f5722;
  --cui-info-text-emphasis: #184c77;
  --cui-warning-text-emphasis: #764705;
  --cui-danger-text-emphasis: #671414;
  --cui-light-text-emphasis: #4a566d;
  --cui-dark-text-emphasis: #323a49;
  --cui-primary-bg-subtle: #cfc7f3;
  --cui-secondary-bg-subtle: #ced2d8;
  --cui-success-bg-subtle: #cbedd6;
  --cui-info-bg-subtle: #c0e6ff;
  --cui-warning-bg-subtle: #feecc5;
  --cui-danger-bg-subtle: #f9d4d4;
  --cui-light-bg-subtle: #f9fafb;
  --cui-dark-bg-subtle: #f3f4f7;
  --cui-primary-border-subtle: #9d92e6;
  --cui-secondary-border-subtle: #9da5b1;
  --cui-success-border-subtle: #96dbad;
  --cui-info-border-subtle: #80c6ff;
  --cui-warning-border-subtle: #fcd88a;
  --cui-danger-border-subtle: #f2a9a9;
  --cui-light-border-subtle: #e7eaee;
  --cui-dark-border-subtle: #aab3c5;
  --cui-white-rgb: 255, 255, 255;
  --cui-black-rgb: 8, 10, 12;
  --cui-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --cui-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --cui-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --cui-body-font-family: var(--cui-font-sans-serif);
  --cui-body-font-size: 1rem;
  --cui-body-font-weight: 400;
  --cui-body-line-height: 1.5;
  --cui-body-color: rgba(37, 43, 54, 0.95);
  --cui-body-color-rgb: 37, 43, 54;
  --cui-body-bg: #fff;
  --cui-body-bg-rgb: 255, 255, 255;
  --cui-emphasis-color: #080a0c;
  --cui-emphasis-color-rgb: 8, 10, 12;
  --cui-secondary-color: rgba(37, 43, 54, 0.681);
  --cui-secondary-color-rgb: 37, 43, 54;
  --cui-secondary-bg: #e7eaee;
  --cui-secondary-bg-rgb: 231, 234, 238;
  --cui-tertiary-color: rgba(37, 43, 54, 0.38);
  --cui-tertiary-color-rgb: 37, 43, 54;
  --cui-tertiary-bg: #f3f4f7;
  --cui-tertiary-bg-rgb: 243, 244, 247;
  --cui-body-color-dark: rgba(255, 255, 255, 0.87);
  --cui-body-color-rgb-dark: 255, 255, 255;
  --cui-body-bg-dark: #212631;
  --cui-body-bg-rgb-dark: 33, 38, 49;
  --cui-emphasis-color-dark: #fff;
  --cui-emphasis-color-rgb-dark: 255, 255, 255;
  --cui-secondary-color-dark: rgba(255, 255, 255, 0.6);
  --cui-secondary-color-rgb-dark: 255, 255, 255;
  --cui-secondary-bg-dark: #323a49;
  --cui-secondary-bg-rgb-dark: 50, 58, 73;
  --cui-tertiary-color-dark: rgba(255, 255, 255, 0.38);
  --cui-tertiary-color-rgb-dark: 255, 255, 255;
  --cui-tertiary-bg-dark: #2a303d;
  --cui-tertiary-bg-rgb-dark: 42, 48, 61;
  --cui-high-emphasis: rgba(37, 43, 54, 0.95);
  --cui-medium-emphasis: rgba(37, 43, 54, 0.681);
  --cui-disabled: rgba(37, 43, 54, 0.38);
  --cui-high-emphasis-inverse: rgba(255, 255, 255, 0.87);
  --cui-medium-emphasis-inverse: rgba(255, 255, 255, 0.6);
  --cui-disabled-inverse: rgba(255, 255, 255, 0.38);
  --cui-heading-color: inherit;
  --cui-link-color: #5856d6;
  --cui-link-color-rgb: 88, 86, 214;
  --cui-link-decoration: underline;
  --cui-link-hover-color: #4645ab;
  --cui-link-hover-color-rgb: 70, 69, 171;
  --cui-code-color: #d63384;
  --cui-highlight-color: rgba(37, 43, 54, 0.95);
  --cui-highlight-bg: #fff3cd;
  --cui-border-width: 1px;
  --cui-border-style: solid;
  --cui-border-color: #dbdfe6;
  --cui-border-color-translucent: rgba(8, 10, 12, 0.175);
  --cui-border-radius: 0.375rem;
  --cui-border-radius-sm: 0.25rem;
  --cui-border-radius-lg: 0.5rem;
  --cui-border-radius-xl: 1rem;
  --cui-border-radius-xxl: 2rem;
  --cui-border-radius-2xl: var(--cui-border-radius-xxl);
  --cui-border-radius-pill: 50rem;
  --cui-box-shadow: 0 0.5rem 1rem rgba(8, 10, 12, 0.15);
  --cui-box-shadow-sm: 0 0.125rem 0.25rem rgba(8, 10, 12, 0.075);
  --cui-box-shadow-lg: 0 1rem 3rem rgba(8, 10, 12, 0.175);
  --cui-box-shadow-inset: inset 0 1px 2px rgba(8, 10, 12, 0.075);
  --cui-focus-ring-width: 0.25rem;
  --cui-focus-ring-opacity: 0.25;
  --cui-focus-ring-color: rgba(88, 86, 214, 0.25);
  --cui-form-valid-color: #1b9e3e;
  --cui-form-valid-border-color: #1b9e3e;
  --cui-form-invalid-color: #e55353;
  --cui-form-invalid-border-color: #e55353;
}

:root,
[data-coreui-theme="light"] {
  --cui-blue: #0d6efd;
  --cui-indigo: #6610f2;
  --cui-purple: #6f42c1;
  --cui-pink: #d63384;
  --cui-red: #dc3545;
  --cui-orange: #fd7e14;
  --cui-yellow: #ffc107;
  --cui-green: #198754;
  --cui-teal: #20c997;
  --cui-cyan: #0dcaf0;
  --cui-black: #080a0c;
  --cui-white: #fff;
  --cui-gray: #6d7d9c;
  --cui-gray-dark: #323a49;
  --cui-gray-100: #f3f4f7;
  --cui-gray-200: #e7eaee;
  --cui-gray-300: #dbdfe6;
  --cui-gray-400: #cfd4de;
  --cui-gray-500: #aab3c5;
  --cui-gray-600: #6d7d9c;
  --cui-gray-700: #4a566d;
  --cui-gray-800: #323a49;
  --cui-gray-900: #212631;
  --cui-primary: #5856d6;
  --cui-secondary: #6b7785;
  --cui-success: #1b9e3e;
  --cui-info: #39f;
  --cui-warning: #f9b115;
  --cui-danger: #e55353;
  --cui-light: #f3f4f7;
  --cui-dark: #212631;
  --cui-primary-rgb: 88, 86, 214;
  --cui-secondary-rgb: 107, 119, 133;
  --cui-success-rgb: 27, 158, 62;
  --cui-info-rgb: 51, 153, 255;
  --cui-warning-rgb: 249, 177, 21;
  --cui-danger-rgb: 229, 83, 83;
  --cui-light-rgb: 243, 244, 247;
  --cui-dark-rgb: 33, 38, 49;
  --cui-primary-text-emphasis: #3634a3;
  --cui-secondary-text-emphasis: #212233;
  --cui-success-text-emphasis: #0f5722;
  --cui-info-text-emphasis: #184c77;
  --cui-warning-text-emphasis: #764705;
  --cui-danger-text-emphasis: #671414;
  --cui-light-text-emphasis: #4a566d;
  --cui-dark-text-emphasis: #323a49;
  --cui-primary-bg-subtle: #cfc7f3;
  --cui-secondary-bg-subtle: #ced2d8;
  --cui-success-bg-subtle: #cbedd6;
  --cui-info-bg-subtle: #c0e6ff;
  --cui-warning-bg-subtle: #feecc5;
  --cui-danger-bg-subtle: #f9d4d4;
  --cui-light-bg-subtle: #f9fafb;
  --cui-dark-bg-subtle: #f3f4f7;
  --cui-primary-border-subtle: #9d92e6;
  --cui-secondary-border-subtle: #9da5b1;
  --cui-success-border-subtle: #96dbad;
  --cui-info-border-subtle: #80c6ff;
  --cui-warning-border-subtle: #fcd88a;
  --cui-danger-border-subtle: #f2a9a9;
  --cui-light-border-subtle: #e7eaee;
  --cui-dark-border-subtle: #aab3c5;
  --cui-white-rgb: 255, 255, 255;
  --cui-black-rgb: 8, 10, 12;
  --cui-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --cui-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --cui-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --cui-body-font-family: var(--cui-font-sans-serif);
  --cui-body-font-size: 1rem;
  --cui-body-font-weight: 400;
  --cui-body-line-height: 1.5;
  --cui-body-color: rgba(37, 43, 54, 0.95);
  --cui-body-color-rgb: 37, 43, 54;
  --cui-body-bg: #fff;
  --cui-body-bg-rgb: 255, 255, 255;
  --cui-emphasis-color: #080a0c;
  --cui-emphasis-color-rgb: 8, 10, 12;
  --cui-secondary-color: rgba(37, 43, 54, 0.681);
  --cui-secondary-color-rgb: 37, 43, 54;
  --cui-secondary-bg: #e7eaee;
  --cui-secondary-bg-rgb: 231, 234, 238;
  --cui-tertiary-color: rgba(37, 43, 54, 0.38);
  --cui-tertiary-color-rgb: 37, 43, 54;
  --cui-tertiary-bg: #f3f4f7;
  --cui-tertiary-bg-rgb: 243, 244, 247;
  --cui-body-color-dark: rgba(255, 255, 255, 0.87);
  --cui-body-color-rgb-dark: 255, 255, 255;
  --cui-body-bg-dark: #212631;
  --cui-body-bg-rgb-dark: 33, 38, 49;
  --cui-emphasis-color-dark: #fff;
  --cui-emphasis-color-rgb-dark: 255, 255, 255;
  --cui-secondary-color-dark: rgba(255, 255, 255, 0.6);
  --cui-secondary-color-rgb-dark: 255, 255, 255;
  --cui-secondary-bg-dark: #323a49;
  --cui-secondary-bg-rgb-dark: 50, 58, 73;
  --cui-tertiary-color-dark: rgba(255, 255, 255, 0.38);
  --cui-tertiary-color-rgb-dark: 255, 255, 255;
  --cui-tertiary-bg-dark: #2a303d;
  --cui-tertiary-bg-rgb-dark: 42, 48, 61;
  --cui-high-emphasis: rgba(37, 43, 54, 0.95);
  --cui-medium-emphasis: rgba(37, 43, 54, 0.681);
  --cui-disabled: rgba(37, 43, 54, 0.38);
  --cui-high-emphasis-inverse: rgba(255, 255, 255, 0.87);
  --cui-medium-emphasis-inverse: rgba(255, 255, 255, 0.6);
  --cui-disabled-inverse: rgba(255, 255, 255, 0.38);
  --cui-heading-color: inherit;
  --cui-link-color: #5856d6;
  --cui-link-color-rgb: 88, 86, 214;
  --cui-link-decoration: underline;
  --cui-link-hover-color: #4645ab;
  --cui-link-hover-color-rgb: 70, 69, 171;
  --cui-code-color: #d63384;
  --cui-highlight-color: rgba(37, 43, 54, 0.95);
  --cui-highlight-bg: #fff3cd;
  --cui-border-width: 1px;
  --cui-border-style: solid;
  --cui-border-color: #dbdfe6;
  --cui-border-color-translucent: rgba(8, 10, 12, 0.175);
  --cui-border-radius: 0.375rem;
  --cui-border-radius-sm: 0.25rem;
  --cui-border-radius-lg: 0.5rem;
  --cui-border-radius-xl: 1rem;
  --cui-border-radius-xxl: 2rem;
  --cui-border-radius-2xl: var(--cui-border-radius-xxl);
  --cui-border-radius-pill: 50rem;
  --cui-box-shadow: 0 0.5rem 1rem rgba(8, 10, 12, 0.15);
  --cui-box-shadow-sm: 0 0.125rem 0.25rem rgba(8, 10, 12, 0.075);
  --cui-box-shadow-lg: 0 1rem 3rem rgba(8, 10, 12, 0.175);
  --cui-box-shadow-inset: inset 0 1px 2px rgba(8, 10, 12, 0.075);
  --cui-focus-ring-width: 0.25rem;
  --cui-focus-ring-opacity: 0.25;
  --cui-focus-ring-color: rgba(88, 86, 214, 0.25);
  --cui-form-valid-color: #1b9e3e;
  --cui-form-valid-border-color: #1b9e3e;
  --cui-form-invalid-color: #e55353;
  --cui-form-invalid-border-color: #e55353;
}
